import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Our Top Six Bachelor in Paradise Smiles | The Smile Stylist" 
    description='Who won this year best smiles from "Bachelor in Paradise" on ABC? Check out our super six bachelor and bachelorettes smile and who they are' 
    keywords="Smile Makeover, Smile Consultation" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>Our Top Six Bachelor in Paradise Smiles!</h1>
              <h2>Do the BIP Stars with Better Smiles get a Better Shot at Love?</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <img src={ require("../../images/desktop/_blog/pics/bachelor-side.png" )} className="img-thumbnail img-news hidden-md hidden-lg" />
            <p className="lead">We’ll be the first to admit: Bachelor in Paradise is definitely a guilty pleasure of ours. And no, not because of the drama or limitlessly creative cast intros… but because of the
              <a href="/blog/better-smile-better-life"><strong>stunning smiles!</strong></a>
            </p>
            <p>That said, let’s take a look at our top six favourite Bachelor in Paradise smiles of the season:</p>
            <h3>1. Kristina Schulman</h3>
            <img src={ require("../../images/desktop/_blog/pics/Kristina.png" )} className="bl__imga" />
            <p>We had to put Kristina first – she’s a dental professional herself (and come on, an undeniable sweetheart which makes her smile all that more inviting)! Kristina’s teeth are straight, white, and surely one that caught the attention of Dean at the beginning of the season… which leads us to our next controversial pick…</p>
            <h3>2. Dean Unglert</h3>
            <img src={ require("../../images/desktop/_blog/pics/Dean.png" )} className="bl__imga" />
            <p>Though we aren’t big fans of how this BIP contender plays the field, Dean’s charmingly irresistible smile was enough to win him his own “Deanie Babies” fan club – seriously, he’s got a lot of swooning ladies who admire his unique charm. And while things between him and Kristina unfortunately took a destructive turn, the next “lucky” lady to soak up Dean’s charming smile is…</p>
            <h3>3. Danielle Lombard</h3>
            <img src={ require("../../images/desktop/_blog/pics/Danielle.png" )} className="bl__imga" />
            <p>Could the steamy dreamy Danielle “D-Lo” Lombard have stolen Dean’s heart from Kristina because of smiling more? ...Very possible. D-lo’s beautiful grin earns her a spot in our top 6 stunning Paradise smiles, and she isn’t afraid to show it off!</p>
            <h3>4. Amanda Stanton</h3>
            <img src={ require("../../images/desktop/_blog/pics/Amanda.png" )} className="bl__imga" />
            <p>Amanda’s striking smile is seemingly enough to win the attention of every alpha male with whom she crosses crab-infested paths – but this year, it seems to have reeled in a rather undesirable suitor (cough – Alex – cough). Good thing Robby came along and stole her heart with his glow stick-themed advances! Keep smiling, beautiful!</p>
            <h3>5. Ben Zorn</h3>
            <img src={ require("../../images/desktop/_blog/pics/Ben.png" )} className="bl__imga" />
            <p>Is Ben’s literally picture-perfect smile enough to compensate for his weird dog obsession? Typically, it would. But Ben… you might be a special case. It’s okay, you still have a lot going for you in that grin. Our suggestion: quit the K-9 talk and show us more canine!</p>
            <h3>6. Alexis Waters</h3>
            <img src={ require("../../images/desktop/_blog/pics/Alexis.png" )} className="bl__imga" />
            <p>What’s that out of the… you know, Waters?! While it’s arguable this wild spirit makes her grand entrances as a shark and not a dolphin (at the end of the day, we don’t care) – whatever that sea creature is: her smile is entrancing!</p>

            <p>So… which Bachelor in Paradise smile would win your heart on the drama-ridden island? (We’re still sticking to Kristina as our #1, for obvious reasons)!</p>
          </div>
          <div className="col-sm-4 blog-right">
            <img src={ require("../../images/desktop/_blog/pics/bachelor-side.png" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.4s" /><br />
            <img src={ require("../../images/desktop/_blog/pics/bachelor-side2.png" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.6s" />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage